import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./src/styles/style.less";
import "./src/fonts/bernadette.ttf";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import { LangProvider } from "./src/context/LangContext";

export const wrapRootElement = ({ element }) => (
  <GoogleReCaptchaProvider reCaptchaKey="6LfHRs4ZAAAAAMbEPB5evpWjj7hFrfUP5lBNrPnY">
    <LangProvider>{element}</LangProvider>
  </GoogleReCaptchaProvider>
);
