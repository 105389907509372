// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-carte-menu-tsx": () => import("./../../../src/pages/carte-menu.tsx" /* webpackChunkName: "component---src-pages-carte-menu-tsx" */),
  "component---src-pages-carte-saison-tsx": () => import("./../../../src/pages/carte-saison.tsx" /* webpackChunkName: "component---src-pages-carte-saison-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-galerie-tsx": () => import("./../../../src/pages/galerie.tsx" /* webpackChunkName: "component---src-pages-galerie-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

