import React, { FunctionComponent } from "react";

interface LangState {
  lang: keyof Labels;
  setLang?: (lang: keyof Labels) => void;
}

interface Labels {
  fr: string;
  de: string;
  it: string;
  en: string;
}

const defaultState: LangState = {
  lang: "fr",
};

const LangContext = React.createContext(defaultState);

const LangProvider: FunctionComponent = props => {
  const [lang, setLang] = React.useState<keyof Labels>("fr");

  React.useEffect(() => {
    if (localStorage.getItem("lang")) {
      setLang(localStorage.getItem("lang") as keyof Labels);
    }
  }, []);

  const changeLang = (language: keyof Labels) => {
    localStorage.setItem("lang", language);
    setLang(language);
  };

  return (
    <LangContext.Provider
      value={{
        lang,
        setLang: changeLang,
      }}
    >
      {props.children}
    </LangContext.Provider>
  );
};

export default LangContext;
export { LangProvider, Labels };
